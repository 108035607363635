import { Pipe, PipeTransform, inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl',
  standalone: true,
})
export class SafeUrlPipe implements PipeTransform {
  private domSanitizer = inject(DomSanitizer);


  // This pipe should NOT be used with user input data
  // If used with user supplied data, this could cause XSS attacks
  // We will only use this with values known to be safe defined in environments
  transform(value: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(value);
  }
}
